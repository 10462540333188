import {gql} from '@apollo/client';

export const QUOTATION_QUERY = gql`
	query quotation($id: ID!) {
		quotation(id: $id) {
			id
			advisorId
			appointmentAvailabilities {
				id
				address {
					street
					zipCode
					city
				}
				slots {
					rank
					startDate
					endDate
				}
			}
			automaticPricing {
				id
				price
				scoreValuation
			}
			brand
			condition
			customer {
				id
			}
			door
			energy
			door
			entryIntoService
			gearType
			generation
			mileage
			motorization
			packageName
			price
			product {
				id
				advisor {
					email
				}
				inspection {
					id
					startDate
					endDate
					address
					gmapUrl
					status
				}
			}
			status
			traction
			unknownBrand
			unknownVersion
			unknownYear
			unknownPackageName
			unknownMotorization
			version
			priceEstimationEvaluation
			customerPriceEstimation
			canUpdate
			agent {
				firstName
				lastName
				pic
				phone
			}
		}
	}
`;
export const EVALUATE_PRICE_ESTIMATION_MUTATION = gql`
	mutation evaluatePriceEstimationMutation(
		$quotationId: ID!
		$evaluation: PriceEstimationEvaluation!
	) {
		evaluatePriceEstimation(
			quotationId: $quotationId
			evaluation: $evaluation
		) {
			id
			priceEstimationEvaluation
		}
	}
`;
export const SET_CUSTOMER_PRICE_ESTIMATION_MUTATION = gql`
	mutation setCustomerPriceEstimation($quotationId: ID!, $price: Int!) {
		setCustomerPriceEstimation(quotationId: $quotationId, price: $price) {
			id
			customerPriceEstimation
		}
	}
`;
export const SET_CLICKED_BOOKING_CTA_TYPE_MUTATION = gql`
	mutation setClickedBookingCtaType(
		$quotationId: ID!
		$ctaType: PriceEstimationEvaluation!
	) {
		setClickedBookingCtaType(quotationId: $quotationId, ctaType: $ctaType)
	}
`;

export const REFRESH_QUOTATION_MUTATION = gql`
	mutation ($quotationId: ID!, $updates: QuotationRefreshInput!) {
		refreshQuotation(quotationId: $quotationId, updates: $updates) {
			id
		}
	}
`;
